

 
 //if($('#top').length) {
   if (!window.matchMedia( "(max-width: 1024px)" ).matches) {
    $(".l-header__side").addClass('is-show');
   }
  
   var thisOffset;
   var endOffset;
  
   thisOffset = $('.l-header__side').offset().top + $('.l-header__side').outerHeight();
   thisOffset = thisOffset / 2;
   endOffset = $('.l-footer').offset().top - window.innerHeight;

  let bannerfirstFlag = false;

  $(window).on('scroll', function() {
    let fullopenmenu = $(".fullScreenMenu-content").hasClass('is-open');
    
   if (window.matchMedia( "(max-width: 1024px)" ).matches && !fullopenmenu) {
     if ($(window).scrollTop()  > thisOffset && $(window).scrollTop() < endOffset ) {
       
       if(!bannerfirstFlag){
         $(".l-header__side").addClass('is-show');
         $(".l-header__side").fadeIn('fast');
         bannerfirstFlag = true;
       }
   
      //  let js_banner_animation_flag = $(".l-header__side").hasClass('is-show');
      //  let js_banner_reduced_flag = $(".l-header__side").hasClass('is-show');
   
     } else {
       $(".l-header__side").removeClass('is-show');
       $(".l-header__side").fadeOut('fast');
       bannerfirstFlag = false;
     }
   } else {
      $(".l-header__side").fadeIn('fast');
   }
  });
 //}


