// import scrollify from '../../plugins/jquery.scrollify.js';

// $.scrollify({
//   section:'.scrollify', //対象要素を指定
//   easing: 'swing', // イージングを指定
//   scrollSpeed: 600, // スクロール時の速度
//   updateHash: false, // スクロール時アドレスバーのURLを更新
//   standardScrollElements: '.l-footer',
//   //standardScrollElements: '.section03,.section04',
// });
/*===============================================
	 * 現在スクロール位置によるグローバルナビのアクティブ表示
================================================*/
$(function () {
  var scrollMenu = function () {
    var array = {
      //"#area": 0,
      "#area1": 0,
      "#area2": 0,
      "#area3": 0,
      "#area4": 0,
    };

    var $globalNavi = new Array();
    var count = 1;
    for (var key in array) {
      if ($(key).offset()) {
        array[key] = $(key).offset().top - 300;
        $globalNavi[key] = '.area' + count;
        count++;
      }
    }

    var timer = 0;
    window.onresize = function () {
      if (timer > 0) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        var $globalNavi = new Array();
        var count = 0;
        for (var key in array) {
          if ($(key).offset()) {
            array[key] = $(key).offset().top - 300;
            $globalNavi[key] = '.area' + count;
            count++;
          }
        }
      }, 200);
    };


    $(window).scroll(function () {
      if (!$("body").hasClass("js-fullScreenMenu-open")) {
        // console.log("aaa")
        for (var key in array) {
          if ($(window).scrollTop() > array[key]) {
            $(".l-header__side-contents-nav-list li").each(function () {
              $(this).removeClass("is-active");
            });
            //console.log($globalNavi[key]);
            $($globalNavi[key]).addClass("is-active");
          }
        }
      }
    });
  };

  if($('#top').length) {
    // 実行
    scrollMenu();
  } else {
    $('.l-header__side-contents-nav').css('display','none');
  }
});











//2用：アニメーション属性を持つ要素を非表示、animated属性を追加
$('*[animation]').addClass('invisible animated');

$(window).scroll(function () {
  $('*[animation]').each(function () {
    //アニメーションをさせる要素の上の位置
    var imgPos = $(this).offset().top;
    //ウインドウ上部の位置
    var scroll = $(window).scrollTop();
    //アニメーションを発火させる位置
    var position = imgPos - window.innerHeight + window.innerHeight / 5;

    //animation属性に記載されたアニメーション名を取得
    if (this.hasAttribute('animation')) {
        var animation = this.getAttribute('animation');
    }
    //発火位置まで来たら要素を表示し、アニメーションをclass名に追加
    if (scroll > position) {
      $(this).removeClass('invisible').addClass(animation);
    }
    //ページのトップまでスクロールしたら要素を非表示（リセット）
    if (scroll < 10) {
      $(this).removeClass(animation).addClass('invisible');
    }
    
  });
});

