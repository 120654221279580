import Simplemodal from '../../plugins/simplemodal.js';

const fullScreenMenu = new Simplemodal({
  trigger : "data-fullScreenMenu-trigger",
  clickOutSideClose : true,
  onOpen: function() {
    $(".fullScreenMenu-button__text").text("CLOSE");
  },
  onClose: function() {
    $(".fullScreenMenu-button__text").text("MENU");
  }
})